:root {
    --primary: #263e4f;
    --primary-light: #3e6581;
    --secondary: #f95f1c;
    --text-white: rgba(255, 255, 255, 0.438);
    --text: #ffffffb6;
}

body {
    background-image: linear-gradient(to right, var(--primary-light), var(--primary));
    font-family: 'Roboto', sans-serif;
}

.tables-page {
    text-align: center;
    padding: 10px;
    color: var(--text);
    font-weight: 600;
    font-size: 20px;
}

.top-bar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid var(--text-white);
}

.top-bar img {
    padding-top: 10px;
    padding-bottom: 10px;
}

input {
    height: 50px;
}

.loader {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, 0.0);
}

.loader .container-spinner {
    width: 100% !important;
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    height: 50px;
}

.btn-primary {
    background-color: var(--secondary) !important;
    border: none !important;
    height: 50px;
}

.login-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-page {
    display: flex;
    justify-content: center;
    text-align: center;
}

.login-page div:nth-child(1) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 300px;
}

.table-object {
    width: 100%;
    height: 70px;
    background-color: var(--secondary);
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px #0000006c;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    font-size: bold;
}

.product-card {
    width: 100%;
    min-height: 70px;
    max-height: 100px;;
    box-shadow: 0px 0px 10px 0px #0000006c;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.floating {
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: #2F4F4F;
    border-radius: 100000px;
    box-shadow: 0px 0px 10px 0px #0000006c;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    font-size: 20px;
    justify-content: center;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    border: 5px, solid, white
}

.floating.extended {
    width: 140px !important;
}

.line-product-parent {
    background: #ffffffc4;
    border-radius: 15px;
}

.line-product-variant {
    display: flex;
    justify-content: center;
    font-size: 12px;
    padding: 10px;
    background: var(--secondary);
    color: white;
    font-size: 15px;
    text-align: center;
}

.line-product {
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: space-between;
    line-height: 35px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 30px;
}

.variant-line {
    background: #ffffffc4;
    width: 100%;
    height: 50px;
    border-radius: 15px;
    text-align: center;
    line-height: 50px;
    font-weight: 600;
}

.variants-page {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
}

.mall-modal-parent {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0px;
    z-index: 3;
    left: 0px;
}

.mall-modal {
    position: fixed;
    z-index: 4;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px #0000008a;
}

.mall-modal .title {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    background-color: var(--primary);
    color: white;
    font-size: 20px;
}

.mall-modal .body {
    padding: 15px;
    background-color: #f4f4f4;
}

.footer-confirm {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.233);
    padding-top: 15px;
}

.top-bar .arrow-back {
    position: absolute;
    color: white;
    left: 15px;
    line-height: 60px;
    font-size: 30px;
}

.cart .cart-page {
    padding: 15px;
}

.cart-page .receipt {
    background-color: #fff;
    padding: 10px;
    border-radius: 0px;
    font-family: 'Courier Prime', monospace;
}

.receipt .header>div {
    width: 33%;
    text-align: center;
}

.receipt .header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.receipt .body {
    display: flex;
    justify-content: space-between;
}

.receipt .footer {
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    font-weight: bold;
}

.product-description {
    line-height: 18px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.parked_orders_page {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.parked-orders-line {
    width: 100%;
    height: 60px;
    background: white;
    border-radius: 10px;
    text-align: center;
    line-height: 60px;
    font-size: 30px;
}

.flex-categories {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 15px;
    gap: 10px;
}

.category-page {
    padding: 10px;
}

.category-button {
    background-color: var(--secondary);
    text-align: center;
    color: white;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px #0000006c;
}

.category-text {
    min-height: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
}

.department-production{
    margin-top: 10px;
    background-color: white;
    padding: 10px;
    font-family: 'Courier Prime', monospace;
}

.line-department{
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    font-weight: bold;
    font-size: 25px;
}